/* eslint-disable */
import { GOOGLE_MAPS_LIBRARIES, MAPS_API_KEY } from '@common/utils/constants';
import { GoogleMap, useJsApiLoader, Marker, Polyline, InfoWindow } from '@react-google-maps/api';
import { memo, useCallback, useState, FC, useEffect } from 'react';
// import { v4 as uuid } from 'uuid';
import mapStartPoint from '@assets/images/map-start-point.svg';
import mapEndPoint from '@assets/images/map-end-point.svg';
import actualMarker from '@assets/images/active-marker.svg';
import alternativeMarker from '@assets/images/alternative-marker.svg';
import dollar from '@assets/images/dollar.svg';
import dollargrey from '@assets/images/dollargrey.svg';
import noMarker from '@assets/images/noMarker.svg';
import { ITripPoint, IOriginalTrip, IAltTrip } from '@api/types';
import styles from './map.module.scss';
import { MapProps } from './types';
import { GPSTollTimezone } from '@common/services/format/formatTimeZone';
import { uniqueId } from 'lodash';

const defaultProps = {
  center: {
    lat: 40.00400096838636,
    lng: -101.3589861870567
  },
  zoom: 8
};

const Map: FC<MapProps> = ({
  alternativeTrips,
  originalTrip,
  tripType,
  showTollMarkers = true,
  tollData,
  timezone,
  showFullScreenToggle = false
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAPS_API_KEY || '',
    libraries: GOOGLE_MAPS_LIBRARIES
  });
  const [showInfoWindow, setShowInfo] = useState<any>('');
  // ? trips
  const [ogTrip, setOgTrip] = useState<IOriginalTrip>([] as IOriginalTrip);
  useEffect(() => {
    setOgTrip(originalTrip || ([] as IOriginalTrip));
  }, [originalTrip]);
  const [altTrips, setAltTrips] = useState<IAltTrip[]>([]);
  useEffect(() => {
    setAltTrips(alternativeTrips || []);
  }, [alternativeTrips]);

  // ? map center
  const [center, setCenter] = useState(defaultProps.center);
  useEffect(() => {
    if (ogTrip && ogTrip.length !== 0) {
      setCenter(ogTrip[Math.floor(ogTrip.length / 2)]);
    }
    if (ogTrip?.length === 0 && altTrips?.length > 0 && altTrips[0]?.logRecords?.length !== 0) {
      setCenter(altTrips[0].logRecords[Math.floor(altTrips[0].logRecords.length / 2)]);
    }
  }, [ogTrip, altTrips]);

  // ? set map
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const onLoad = useCallback((newMap: google.maps.Map | null) => {
    newMap?.setZoom(defaultProps.zoom);
    setMap(newMap);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  return (
    <section className={styles.container}>
      {isLoaded ? (
        <GoogleMap
          center={center}
          mapContainerClassName={styles.map}
          zoom={defaultProps.zoom}
          onLoad={newMap => onLoad(newMap)}
          onUnmount={onUnmount}
          options={{
            fullscreenControl: showFullScreenToggle,
            fullscreenControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_BOTTOM
            },
            streetViewControl: false,
            zoomControl: false,
            mapTypeControl: false
          }}
        >
          {ogTrip && (
            <>
              <Polyline
                path={ogTrip}
                options={{
                  map,
                  geodesic: true,
                  strokeOpacity: 1.0,
                  strokeColor: tripType === 'actual' ? '#2a28f3' : '#9e33f2',
                  strokeWeight: 4,
                  visible: true,
                  zIndex: tripType === 'actual' ? 4 : 2
                }}
              />
              {/* {ogTrip?.logRecords
                ?.filter((record, index) => index !== 0 && index !== ogTrip.logRecords.length - 1)
                .map(record => (
                  <Marker
                    key={uuid()}
                    icon={actualMarker}
                    position={record}
                    options={{ zIndex: 2 }}
                  />
                ))} */}
            </>
          )}
          {altTrips.length > 0 && (
            <>
              <Polyline
                path={altTrips[0].logRecords}
                options={{
                  map,
                  geodesic: true,
                  strokeOpacity: 1.0,
                  strokeColor: tripType === 'actual' ? '#9e33f2' : '#2a28f3',
                  strokeWeight: 4,
                  visible: true,
                  zIndex: tripType === 'actual' ? 1 : 3
                }}
              />
              {/* {altTrip?.logRecords
                  ?.filter(
                    (record, index) => index !== 0 && index !== altTrip.logRecords.length - 1
                  )
                  .map(record => (
                    <Marker
                      key={uuid()}
                      icon={alternativeMarker}
                      position={record}
                      options={{ zIndex: 1 }}
                    />
                  ))} */}
            </>
          )}
          {/* // ? selected toll-highlight polyline */}
          {/* <Polyline
            path={
              ogTrip?.logRecords?.length > 0
                ? [
                    ogTrip.logRecords[1],
                    ogTrip.logRecords[2],
                    ogTrip.logRecords[3],
                    ogTrip.logRecords[4],
                    ogTrip.logRecords[5]
                  ]
                : []
            }
            options={{
              map,
              geodesic: true,
              strokeOpacity: 1.0,
              strokeColor: '#2A28F3',
              strokeWeight: 4,
              visible: true,
              zIndex: 5
            }}
          /> */}
          {/* // ? markers */}
          {ogTrip?.length > 0 && (
            <Marker
              icon={tripType === 'actual' ? mapStartPoint : actualMarker}
              position={ogTrip[0]}
              options={{ zIndex: tripType === 'actual' ? 4 : 2 }}
            />
          )}
          {ogTrip?.length > 0 && (
            <>
              <Marker
                icon={tripType === 'actual' ? mapEndPoint : actualMarker}
                position={ogTrip.at(-1) as ITripPoint}
                options={{ zIndex: tripType === 'actual' ? 3 : 1 }}
              />
              {showTollMarkers &&
                tollData?.length > 0 &&
                tollData.map(function (item: any, index: any) {
                  return (
                    <Marker
                      key={uniqueId()}
                      icon={
                        tripType === 'actual'
                          ? item?.missedTollUpdatedAt != null
                            ? dollargrey
                            : dollar
                          : noMarker
                      }
                      position={
                        {
                          lat: item.endPoint,
                          lng: item.startPoint
                        } as ITripPoint
                      }
                      onMouseOver={() => setShowInfo(index)}
                      onMouseOut={() => setShowInfo('')}
                    >
                      {showInfoWindow === index && (
                        <InfoWindow position={{ lat: item.endPoint, lng: item.startPoint }}>
                          <div className={styles.tollDetails}>
                          {item?.missedTollUpdatedAt != null && (
                          <><div className={styles.Details}>
                                <h4 className={styles.heading}>Description: </h4>
                                <h4 className={styles.padding}>
                                  Delayed Toll Posting / Toll not invoiced.
                                </h4>
                              </div><br /></>
 )}
                            <div className={styles.Details}>
                              <h4 className={styles.heading}>Date/Time: </h4>
                              <h4 className={styles.padding}>
                                {GPSTollTimezone(tollData[index], timezone)}
                              </h4>
                            </div>
                            <br />
                            <div className={styles.Details}>
                              <h4 className={styles.heading}>Facility: </h4>
                              <h4 className={styles.padding}>{tollData[index].facility}</h4>
                            </div>
                            <br />
                            <div className={styles.Details}>
                              <h4 className={styles.heading}>Plaza: </h4>
                              <h4 className={styles.padding}>{tollData[index].plaza}</h4>
                            </div>
                            <br />
                            <div className={styles.Details}>
                              <h4 className={styles.heading}>tollogic&#8482; Tolls: </h4>
                              <h4 className={styles.padding}>
                                $ {tollData[index].calculatedAmount.toFixed(2)}
                              </h4>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  );
                })}
            </>
          )}
          {altTrips[0]?.logRecords?.length > 0 && (
            <Marker
              icon={tripType === 'alt' ? mapStartPoint : alternativeMarker}
              position={altTrips[0].logRecords[0]}
              options={{ zIndex: tripType === 'actual' ? 1 : 3 }}
            />
          )}
          {altTrips[0]?.logRecords?.length > 0 && (
            <Marker
              icon={tripType === 'alt' ? mapEndPoint : alternativeMarker}
              position={altTrips[0].logRecords.at(-1) as ITripPoint}
              options={{ zIndex: tripType === 'actual' ? 1 : 2 }}
            />
          )}
        </GoogleMap>
      ) : null}
    </section>
  );
};

export default memo(Map);
