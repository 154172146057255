/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-fragments */
import React, { ReactElement } from 'react';
import { Tooltip, Typography, styled, TooltipProps, tooltipClasses } from '@mui/material';

interface TooltipExProps {
  title: string;
  subtitle?: string;
  children: ReactElement; // Enforce ReactElement type for children
  placement?: 'top' | 'bottom' | 'left' | 'right'; // Tooltip position
  displayTooltip?: boolean; // New prop to control tooltip visibility
}

// Custom-styled Tooltip using MUI's documentation approach
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220, // Optional: set a max width
    backgroundColor: '#2C2C2C', // Dark gray background
    color: '#FFFFFF', // White text
    fontSize: '0.875rem', // Adjust font size
    padding: '12px', // Add padding
    borderRadius: '4px', // Rounded corners
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)' // Subtle shadow
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2C2C2C' // Match arrow color to tooltip background
  }
}));

const TooltipEx: React.FC<TooltipExProps> = ({
  title,
  subtitle,
  children,
  placement = 'top',
  displayTooltip = true // Default to true
}) => {
  if (!displayTooltip) {
    // If displayTooltip is false, render children without tooltip
    return children;
  }

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography
            variant="subtitle2"
            sx={{ color: '#FFFFFF', fontSize: '1.3rem', marginBottom: '4px' }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="body2"
              sx={{ fontSize: '1.2rem', fontStyle: 'italic', color: '#DDDDDD' }}
            >
              {subtitle}
            </Typography>
          )}
        </React.Fragment>
      }
      arrow
      placement={placement}
    >
      {children}
    </HtmlTooltip>
  );
};

export default TooltipEx;
