/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { ETableName } from '@common/utils/enums';
import SelectInput from '@common/ui/FilterDropdown/SelectInput';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import queryString from 'query-string';
import {
  clearFleetFleetTagNumbers,
  clearFleetVehicleLicensePlateNumbers,
  clearFleetVehicleLicensePlateStates,
  clearFleetVehicleNames,
  clearFleetmissingAssetsAndTransponders,
  clearTransactionsTagNumbers,
  clearTransactionsType,
  clearTransactionsVehicleLicensePlateNumbers,
  clearTransactionsVehicleNames,
  clearTripAmount,
  clearTripIds,
  clearTripVehicleLicensePlateNumbers,
  clearTripVehicleLicensePlateStates,
  clearTripVehicleNames,
  setFleetTagNumbers,
  setFleetVehicleLicensePlateNumbers,
  setFleetVehicleLicensePlateStates,
  setFleetVehicleNames,
  setFleetmissingAssetsAndTransponders,
  setTransactionsTagNumbers,
  setTransactionsType,
  setTransactionsVehicleLicensePlateNumbers,
  setTransactionsVehicleNames,
  setTripIds,
  setTripVehicleLicensePlateNumbers,
  setTripVehicleLicensePlateStates,
  setTripVehicleNames
} from '@store/features/quick-filters';
import { QuickFilterFormProps } from './types';
import styles from './quick-filter.module.scss';
import { fleetHelperTypes, getTransactionTypes } from './helper';

const QuickFilters: FC<QuickFilterFormProps> = ({ handleQuickFilterChange, className }) => {
  const table = useAppSelector(state => state.table);
  const dispatch = useAppDispatch();
  const fleetQuickFilters = useAppSelector(state => state.table.fleetQuickFilters);
  const tripQuickFilters = useAppSelector(state => state.table.tripQuickFilters);
  const quickFiltersSelection = useAppSelector(state => state.quickFilters);
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  const transactionsQuickFilters = useAppSelector(state => state.table.transactionsQuickFilters);

  useEffect(() => {
    const params = queryString.parse(window.location.search, {
      parseBooleans: true,
      parseNumbers: true
    });

    const crossFilterSource = params['cross-filter'] ? (params.source as ETableName) : undefined;
    if (crossFilterSource && (params.destination as ETableName)) {
      // params?.searchId &&
      //   typeof params?.searchId === 'string' &&
      if (params?.destination === ETableName.FLEET) {
        /** Enable 52 - 54 to enable crossfilter with Missing Type */
        const data: string[] = [];
        data.push(params.searchId ? params.searchId.toString() : '');
        if (params?.vehIds && typeof params.vehIds === 'string') {
          const parsedVehNames: string[] = JSON.parse(params.vehIds || '') || [];
          if (parsedVehNames.length > 0) {
            dispatch(setFleetVehicleNames(parsedVehNames));
          } else {
            dispatch(clearFleetVehicleNames());
          }
        } else {
          dispatch(clearFleetVehicleNames());
        }
      }
      if (params?.destination === ETableName.TRIPS) {
        if (params?.tripIds && typeof params.tripIds === 'string') {
          const parsedTripIds: string[] = JSON.parse(params.tripIds || '') || [];
          if (parsedTripIds.length > 0) {
            dispatch(setTripIds(parsedTripIds.map(t => Number(t))));
          } else {
            dispatch(clearTripIds());
          }
        } else {
          dispatch(clearTripIds());
        }
      }
    }
  }, [window.location.search]);

  const clearCurrentFilter = (type: string, data?: string[] | number[]) => {
    switch (type) {
      case 'fleetVehicleNames':
        if (data) {
          dispatch(setFleetVehicleNames(data as string[]));
        } else {
          dispatch(clearFleetVehicleNames());
        }
        break;
      case 'fleetLicensePlateNumbers':
        if (data) {
          dispatch(setFleetVehicleLicensePlateNumbers(data as string[]));
        } else {
          dispatch(clearFleetVehicleLicensePlateNumbers());
        }
        break;
      case 'fleetLicensePlateStates':
        if (data) {
          dispatch(setFleetVehicleLicensePlateStates(data as string[]));
        } else {
          dispatch(clearFleetVehicleLicensePlateStates());
        }
        break;
      case 'fleetTagNumbers':
        if (data) {
          dispatch(setFleetTagNumbers(data as string[]));
        } else {
          dispatch(clearFleetFleetTagNumbers());
        }
        break;
      case 'fleetMissingAssetsAndTransponders':
        if (data) {
          dispatch(setFleetmissingAssetsAndTransponders(data as string[]));
        } else {
          dispatch(clearFleetmissingAssetsAndTransponders());
        }
        break;
      case 'transactionsType':
        if (data) {
          dispatch(setTransactionsType(data as string[]));
        } else {
          dispatch(clearTransactionsType());
        }
        break;
      case 'tripVehicleNames':
        if (data) {
          dispatch(setTripVehicleNames(data as string[]));
        } else {
          dispatch(clearTripVehicleNames());
        }
        break;
      case 'tripIds':
        if (data) {
          dispatch(setTripIds(data as number[]));
        } else {
          dispatch(clearTripIds());
        }
        break;
      case 'tripLicensePlateNumbers':
        if (data) {
          dispatch(setTripVehicleLicensePlateNumbers(data as string[]));
        } else {
          dispatch(clearTripVehicleLicensePlateNumbers());
        }
        break;
      case 'tripLicensePlateStates':
        if (data) {
          dispatch(setTripVehicleLicensePlateStates(data as string[]));
        } else {
          dispatch(clearTripVehicleLicensePlateStates());
        }
        break;
      case 'tripAmount':
        dispatch(clearTripAmount());
        break;
      case 'transactionsLicensePlateNumbers':
        if (data) {
          dispatch(setTransactionsVehicleLicensePlateNumbers(data as string[]));
        } else {
          dispatch(clearTransactionsVehicleLicensePlateNumbers());
        }
        break;
      case 'transactionsTagNumbers':
        if (data) {
          dispatch(setTransactionsTagNumbers(data as string[]));
        } else {
          dispatch(clearTransactionsTagNumbers());
        }
        break;
      case 'vehicleNames':
        if (data) {
          dispatch(setTransactionsVehicleNames(data as string[]));
        } else {
          dispatch(clearTransactionsVehicleNames());
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      {table.name === ETableName.FLEET && (
        <>
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data => handleQuickFilterChange('vehicleNames', data as string[])}
            includeSearch
            values={fleetQuickFilters?.names || []}
            selection={quickFiltersSelection.fleet.vehicleNames}
            clearFilterSelection={data => clearCurrentFilter('fleetVehicleNames', data)}
            disabled={table.isLoading}
            type="tags"
            placeholder="Name"
          />
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data => handleQuickFilterChange('licensePlateStates', data as string[])}
            includeSearch
            values={fleetQuickFilters?.states?.map(x => x.state_code) || []}
            selection={quickFiltersSelection.fleet.licensePlateStates}
            clearFilterSelection={data => clearCurrentFilter('fleetLicensePlateStates', data)}
            disabled={table.isLoading}
            type="tags"
            placeholder="Plate State"
          />
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data => handleQuickFilterChange('licensePlateNumbers', data as string[])}
            includeSearch
            values={fleetQuickFilters?.plates || []}
            selection={quickFiltersSelection.fleet.licensePlateNumbers}
            clearFilterSelection={data => clearCurrentFilter('fleetLicensePlateNumbers', data)}
            disabled={table.isLoading}
            type="tags"
            placeholder="Plate"
          />

          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data => handleQuickFilterChange('tagNumbers', data as string[])}
            includeSearch
            values={fleetQuickFilters?.tags?.filter(x => x !== '') || []}
            selection={quickFiltersSelection.fleet.tagNumbers}
            clearFilterSelection={data => clearCurrentFilter('fleetTagNumbers', data)}
            disabled={table.isLoading}
            type="tags"
            placeholder="Tag"
          />

          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data =>
              handleQuickFilterChange('MissingAssetsAndTransponders', data as string[])
            }
            includeSearch
            values={fleetHelperTypes.map(x => x.label)}
            selection={quickFiltersSelection.fleet.missingItems}
            clearFilterSelection={data =>
              clearCurrentFilter('fleetMissingAssetsAndTransponders', data)
            }
            disabled={table.isLoading}
            type="tags"
            placeholder="missing item"
          />
        </>
      )}
      {table.name === ETableName.TRIPS && (
        <>
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data => handleQuickFilterChange('vehicleNames', data as string[])}
            includeSearch
            values={tripQuickFilters?.names || []}
            selection={quickFiltersSelection.trips.vehicleNames}
            clearFilterSelection={data => clearCurrentFilter('tripVehicleNames', data)}
            disabled={table.isLoading}
            type="tags"
            placeholder="Name"
          />
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data => handleQuickFilterChange('licensePlateStates', data as string[])}
            includeSearch
            values={tripQuickFilters?.states?.map(x => x?.state_code) || []}
            selection={quickFiltersSelection.trips.licensePlateStates}
            clearFilterSelection={data => clearCurrentFilter('tripLicensePlateStates', data)}
            disabled={table.isLoading}
            type="tags"
            placeholder="Plate State"
          />
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data => handleQuickFilterChange('licensePlateNumbers', data as string[])}
            includeSearch
            values={tripQuickFilters?.plates || []}
            selection={quickFiltersSelection.trips.licensePlateNumbers}
            clearFilterSelection={data => clearCurrentFilter('tripLicensePlateNumbers', data)}
            disabled={table.isLoading}
            type="tags"
            placeholder="Plate"
          />
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={(data, range) =>
              handleQuickFilterChange('amount', data as string[], range)
            }
            values={[]}
            min={tripQuickFilters?.amount.min_amount || 0}
            max={tripQuickFilters?.amount.max_amount || 1000}
            selection={[]}
            rangeSelection={quickFiltersSelection.trips.tollAmount}
            clearFilterSelection={() => clearCurrentFilter('tripAmount')}
            disabled={table.isLoading}
            type="slider"
            placeholder="Amount"
          />
        </>
      )}
      {table.name === ETableName.TRANSACTIONS && (
        <>
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data => handleQuickFilterChange('vehicleNames', data as string[])}
            includeSearch
            values={transactionsQuickFilters?.names || []}
            selection={quickFiltersSelection.transactions.vehicleNames}
            clearFilterSelection={data => clearCurrentFilter('vehicleNames', data)}
            disabled={table.isLoading}
            type="tags"
            placeholder="Name"
          />
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data => handleQuickFilterChange('transactionsType', data as string[])}
            values={getTransactionTypes(isActualTollTab)}
            selection={quickFiltersSelection.transactions.transactionsType}
            clearFilterSelection={data => clearCurrentFilter('transactionsType', data)}
            disabled={table.isLoading}
            type="tags"
            placeholder="Type"
          />
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data =>
              handleQuickFilterChange('transactionsLicensePlateNumbers', data as string[])
            }
            includeSearch
            values={transactionsQuickFilters?.plates || []}
            selection={quickFiltersSelection.transactions.licensePlateNumbers}
            clearFilterSelection={data =>
              clearCurrentFilter('transactionsLicensePlateNumbers', data)
            }
            disabled={table.isLoading}
            type="tags"
            placeholder="Plate"
          />
          <SelectInput
            className={`${styles.input}${className || ''}`}
            handleChange={data =>
              handleQuickFilterChange('transactionsTagNumbers', data as string[])
            }
            includeSearch
            values={transactionsQuickFilters?.tags?.filter(x => x !== '') || []}
            selection={quickFiltersSelection.transactions.tagNumbers}
            clearFilterSelection={data => clearCurrentFilter('transactionsTagNumbers', data)}
            disabled={table.isLoading}
            type="tags"
            placeholder="Tag"
          />
        </>
      )}
    </>
  );
};

export default QuickFilters;
