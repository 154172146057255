/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { clearTable, getTransactionQuickFilters, setTable, setTableName } from '@store/data';
import { setPagination } from '@store/features/pagination';
import { useModifyData } from '@pages/tables/hooks/useModifyData';
import { useSearchTransactionsMutation } from '@api/services/transactions';
import { ETableName } from '@common/utils/enums';
import { transactionHelperTypes } from '../layouts/web-portal/components/smart-filters/QuickFilters/helper';

export const useSetTable = ({ name }: { name: ETableName }) => {
  const dispatch = useAppDispatch();
  const quickFilters = useAppSelector(state => state.quickFilters);
  const body = useModifyData(name);
  const [searchTransactions] = useSearchTransactionsMutation();

  const customerId = useAppSelector(state => state.customer.id);
  const table = useAppSelector(state => state.table);
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);

  body.isActualTollTab = isActualTollTab;

  body.tagNumbers = quickFilters.transactions.tagNumbers || [];
  body.licensePlateNumbers = quickFilters.transactions.licensePlateNumbers || [];
  let errorTollType: number[] = [];
  if (quickFilters.transactions.transactionsType.length > 0) {
    errorTollType = quickFilters.transactions.transactionsType
      .map((item: string) => {
        const type = transactionHelperTypes.find(t => t.label === item);
        return type ? type.value : null;
      })
      .filter((value: number | null): value is number => value !== null);
  }
  body.errorTollType = errorTollType;

  if (body.sort?.fieldName && body.sort?.fieldName === 'amount') {
    body.sort.fieldName = 'toll';
  }

  if (body?.searchableField && body.searchableField === 'AMOUNT') {
    body.searchableField = 'TOLL';
  }

  const handleTransactions = useCallback(async () => {
    dispatch(clearTable());
    dispatch(setTableName(name));
    if (
      customerId &&
      body.customerId &&
      name === ETableName.TRANSACTIONS &&
      customerId === body.customerId
    ) {
      //   dispatch(setTable({ data, isLoading, isError, isSuccess, error, name, isFetching }));
      //   dispatch(
      //     setPagination({
      //       table: name,
      //       dataLength: totalElements || 0,
      //       totalPages: totalPages || 0
      //     })
      //   );
      searchTransactions({ ...body })
        .unwrap()
        .then(res => {
          dispatch(
            setTable({
              data: res.pageContent,
              isLoading: false,
              isError: false,
              isSuccess: true,
              error: undefined,
              isFetching: false
            })
          );
          dispatch(getTransactionQuickFilters(Boolean(body.isActualTollTab))).catch(err => {
            console.log('Err in fetching transaction quick filters ==> ', err);
          });
          dispatch(
            setPagination({
              table: ETableName.TRANSACTIONS,
              dataLength: res.totalElements || 0,
              totalPages: res.totalPages || 0
            })
          );
        })
        .catch(e => {
          console.log(e);
          dispatch(
            setTable({
              data: undefined,
              isLoading: false,
              isError: true,
              isSuccess: false,
              error: undefined,
              isFetching: false
            })
          );
        });
    } else {
      dispatch(
        setTable({
          data: undefined,
          isLoading: false,
          isError: false,
          isSuccess: true,
          error: undefined,
          isFetching: false
        })
      );
    }
  }, [body, dispatch, searchTransactions, name, customerId]);

  useEffect(() => {
    handleTransactions().catch(e => console.log(e));
  }, [handleTransactions, table.fetch]);
};
