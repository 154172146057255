/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useAppSelector } from '@store/hooks';
import { useDateRangeData } from '@pages/dashboard/monthly-detail/header/hooks/useDateRangeData';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment-timezone';
import styles from './dashboard-header.module.scss';

function getAvailableMonthsRange(data: any[]) {
  if (!data || data.length === 0) return { finalFrom: null, totalMonths: 0 };

  const availableMonths = data.map(date => moment.utc(date).format('MMMM YYYY'));
  const fromMonth = moment.utc(data[0]).subtract(11, 'months').format('MMMM YYYY');

  const finalFrom = availableMonths.includes(fromMonth)
    ? fromMonth
    : availableMonths[availableMonths.length - 1];

  // Ensure we only count up to 12 months
  let totalMonths = 0;
  if (availableMonths.indexOf(finalFrom) !== -1) {
    const dttoMonth = moment.utc(data[0]);
    const dtfromMonth = moment.utc(data[availableMonths.indexOf(finalFrom)]);
    const diff = dttoMonth.diff(dtfromMonth, 'months') + 1;
    totalMonths = Math.min(12, diff);
  } else totalMonths = 1;

  return { finalFrom, totalMonths };
}

const DashboardHeader = () => {
  const companyName = useAppSelector(state => state.customer.companyName);
  const { data, loader } = useDateRangeData();
  const lastUpdated = moment.utc(data[0]).endOf('month').format('MMMM DD, YYYY');
  const to = moment.utc(data[0]).format('MMMM YYYY');
  // const from = moment.utc(data[0]).subtract(11, 'months').format('MMMM YYYY');
  const { finalFrom, totalMonths } = getAvailableMonthsRange(data);

  return (
    <header className={styles.container}>
      <section className={styles.title}>
        {loader ? (
          <>
            <Skeleton width={200} />
            <Skeleton width={150} />
            <Skeleton width={250} />
          </>
        ) : (
          <>
            <span className={styles.customer}>{companyName}</span>
            <p className={styles['updated-by']}>Last updated - {lastUpdated}</p>
            <p>
              Showing the last {totalMonths} months ({finalFrom} to {to})
            </p>
          </>
        )}
      </section>
    </header>
  );
};

export default DashboardHeader;
