import { useEffect } from 'react';
import Meta from '@common/components/meta';
import { useLocation } from 'react-router-dom';
import { setTransactionTab } from '@store/features/helpers';
import { ETableName } from '@common/utils/enums';
import { setFilters } from '@store/features/filters';
import { clearTransactionsType, setTransactionsType } from '@store/features/quick-filters';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import _ from 'lodash';
// import Help from '@components/help';
import AddNewTransaction from './modals/add/AddNew';
import ViewTransaction from './modals/view/View';
import { useSetTable } from './hooks';
import WebPortalTable from '../layouts/web-portal';
import GeotabTable from '../layouts/geotab-addin';
import { transactionHelperTypes } from '../layouts/web-portal/components/smart-filters/QuickFilters/helper';

const Transactions = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const documentId = queryParams?.get('document') || null;
    const transactionType = queryParams.get('searchId') || null;
    const crossFilterDate = {
      from: queryParams.get('from')?.trim() || '',
      to: queryParams.get('to')?.trim() || ''
    };
    if (transactionType) {
      dispatch(clearTransactionsType());
      const matchedType = transactionHelperTypes.find(type => type.label === transactionType);
      dispatch(setTransactionsType(matchedType?.label ? [matchedType?.label] : []));
      if (crossFilterDate) {
        dispatch(
          setFilters({
            table: ETableName.TRANSACTIONS,
            filters: {
              date: crossFilterDate
            }
          })
        );
      }
    }
    if (documentId) {
      dispatch(setTransactionTab(true));
    }
  }, [dispatch, location.search]);
  const metaOptions = {
    title: 'Transactions',
    keywords: 'Transactions'
  };
  const name = ETableName.TRANSACTIONS;
  useSetTable({ name });
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  return (
    <>
      {/* <Help location="toll_transactions" /> */}
      <Meta {...metaOptions} />
      {isGeotab ? (
        <GeotabTable
          title={name}
          displayName="toll transactions"
          button={{ label: 'Add New Transaction', Modal: AddNewTransaction }}
          tableName={name}
          datesFiltering
          ViewModal={ViewTransaction}
        />
      ) : (
        <WebPortalTable title="toll transactions" />
      )}
    </>
  );
};

export default Transactions;
