/* eslint-disable no-nested-ternary */
import { ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  DATE_FIELDS_ARRAY,
  NUMBER_FIELDS_ARRAY,
  tollDocumentStatuses
} from '@common/utils/constants';
// import { formatDistance } from '@common/services/format';
import {
  IFleetFilters,
  ITollDocumentsFilters,
  ITransactionsFilters,
  ITripsFilters
} from '@common/types/filters';
import { useGetListsData } from './useGetListsData';
import {
  fleetHelperTypes,
  transactionHelperTypes
} from '../layouts/web-portal/components/smart-filters/QuickFilters/helper';

dayjs.extend(utc);
dayjs.extend(timezone);

export const useModifyData = (table: ETableName) => {
  const filters = useAppSelector(state => state.filters[table]);
  const search = useAppSelector(state => state.search[table]);
  const sort = useAppSelector(state => state.sort[table]);
  const pagination = useAppSelector(state => state.pagination[table]);
  const isMetric = useAppSelector(state => state.auth.isMetric);
  const customer = useAppSelector(state => state.customer);
  const quickFilters = useAppSelector(state => state.quickFilters);
  const [body, setBody] = useState<AnyObject>({});
  const location = useLocation();
  const params = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true });
  const crossFilterSource = params['cross-filter'] ? (params.source as ETableName) : undefined;
  // eslint-disable-next-line max-len, prettier/prettier
  const { getVehicleClass, getTagTypeID, getDevicePlan, getBillingStatus } = useGetListsData();
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  const isMobileView = window.matchMedia('(max-width: 480px)').matches;

  useEffect(() => {
    if (
      isGeotab &&
      !isMobileView &&
      [ETableName.TRANSACTIONS, ETableName.FLEET, ETableName.TRIPS].includes(table)
    ) {
      if (filters?.by && 'date' in filters.by && filters?.by?.date) {
        const { date } = filters.by;
        setBody(() => ({
          date: {
            startDate:
              date.from && date.to ? dayjs(date.from).format('YYYY-MM-DDTHH:mm:ss') : date.from,
            endDate: date.to && date.from ? dayjs(date.to).format('YYYY-MM-DDTHH:mm:ss') : date.to
          },
          statementId: table === ETableName.TRANSACTIONS ? params.document || '' : ''
        }));
      }
      setBody(prev => ({
        ...prev,
        customerId: customer.id,
        pageIndex: (pagination?.currentPage || 1) - 1,
        pageSize: pagination?.showBy || 10,
        // eslint-disable-next-line no-nested-ternary
        searchKey:
          search?.field &&
          DATE_FIELDS_ARRAY.includes(search.field) &&
          search?.by &&
          dayjs(search?.by).isValid()
            ? dayjs(search?.by).format('YYYY-MM-DDTHH:mm:ss')
            : // : search?.field && search?.field === 'trip distance' && search?.by && !isMetric
            // ? Math.floor(formatDistance(search.by as number, 'miles'))
            // search?.field && search?.field === 'issuing agency' && search?.by
            // ? tagIssuingAgencies?.find(agency => agency.name === search.by)?.id
            search?.field &&
              NUMBER_FIELDS_ARRAY.includes(_.lowerCase(search.field)) &&
              Number(search?.by) >= 0
            ? Number(search.by)
            : search?.by
            ? search?.by
            : crossFilterSource
            ? params.filter
            : '',
        searchableField:
          search?.field && search?.field === 'tollogic™ Tolls'
            ? 'GPS CALCULATED TOLLS'
            : search?.field
            ? search.field.toUpperCase()
            : crossFilterSource
            ? params.destination === ETableName.FLEET
              ? 'name'
              : params.destination === ETableName.TRIPS
              ? 'vehicle name'
              : ''
            : '',
        sort: {
          fieldName:
            sort?.by === 'tollogic™ Tolls' || sort?.by === 'GPS Calculated Tolls'
              ? 'estimated toll'
              : sort?.by || '',
          order: _.upperCase(sort?.order || '')
        }
      }));
    } else {
      if (filters?.by && 'date' in filters.by && filters?.by?.date) {
        const { date, ...rest } = filters.by;
        setBody(prev => ({
          ...rest,
          ...prev,
          date: {
            startDate: date.from ? dayjs(date.from).format('YYYY-MM-DDTHH:mm:ss') : date.from,
            endDate: date.to ? dayjs(date.to).format('YYYY-MM-DDTHH:mm:ss') : date.to
          }
        }));
      }
      if (filters?.by && 'toll' in filters.by && filters?.by?.toll) {
        const { toll, ...rest } = filters.by;
        setBody(prev => ({
          ...rest,
          ...prev,
          toll: {
            from: toll.from,
            to: toll.to
          }
        }));
      }
      if (filters?.by && table === ETableName.FLEET) {
        // eslint-disable-next-line max-len, prettier/prettier
        const {
          vehicleAxles,
          vehicleClass,
          tagType,
          devicePlan,
          deviceType,
          billingStatus,
          date,
          gpsReliability,
          trailers,
          deleted,
          blocked
        } = filters.by as IFleetFilters;
        setBody(prev => ({
          ...prev,
          vehicleAxles: Number(vehicleAxles) || null,
          vehicleClass: getVehicleClass(vehicleClass),
          // tagType: getTagType(tagType),
          tagType: getTagTypeID(tagType),
          devicePlan: getDevicePlan(devicePlan),
          deviceType,
          billingStatus: getBillingStatus(billingStatus),
          date: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            startDate: date?.from ? dayjs(date.from).format('YYYY-MM-DDTHH:mm:ss') : date?.from,
            endDate: date?.to ? dayjs(date.to).format('YYYY-MM-DDTHH:mm:ss') : date?.to
          },
          gpsReliability: gpsReliability || '',
          trailers,
          deleted,
          blocked
        }));
      }
      if (filters?.by && table === ETableName.TOLLDOCUMENTS) {
        const { status, invoiceIssuer, userId, date, ...rest } =
          filters.by as ITollDocumentsFilters;
        setBody(prev => ({
          ...rest,
          ...prev,
          status: status
            ? Number(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                Object.entries(tollDocumentStatuses).find(
                  type => _.kebabCase(type[1]) === _.kebabCase(status)
                )![0]
              )
            : -1 || -1,
          invoiceIssuer: invoiceIssuer || '',
          date: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            startDate: date?.from ? dayjs(date.from).utc(false).format() : date?.from,
            endDate: date?.to ? dayjs(date.to).utc(false).format() : date?.to
          }
          // invoiceType: invoiceType
          //   ? Number(
          //       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          //       Object.entries(invoiceTypes).find(
          //         type => _.kebabCase(type[1]) === _.kebabCase(invoiceType)
          //       )![0]
          //     )
          //   : '' || '',
          // userId: userId || user?.id || ''
        }));
      }
      if (filters?.by && table === ETableName.TRANSACTIONS) {
        const { date, ...rest } = filters.by as ITransactionsFilters;
        let errorTollType: number[] = [];
        if (quickFilters.transactions.transactionsType.length > 0) {
          errorTollType = quickFilters.transactions.transactionsType
            .map((item: string) => {
              const type = transactionHelperTypes.find(t => t.label === item);
              return type ? type.value : null;
            })
            .filter((value: number | null): value is number => value !== null);
        }
        setBody(prev => ({
          ...rest,
          ...prev,
          date: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            startDate: date?.from ? dayjs(date.from).format('YYYY-MM-DDTHH:mm:ss') : date?.from,
            endDate: date?.to ? dayjs(date.to).format('YYYY-MM-DDTHH:mm:ss') : date?.to
          },
          tagNumbers: quickFilters.transactions.tagNumbers || [],
          licensePlateNumbers: quickFilters.transactions.licensePlateNumbers || [],
          errorTollType,
          statementId: params.document || '',
          vehicleNames: quickFilters.transactions.vehicleNames || []
        }));
      }
      if (filters?.by && table === ETableName.TRIPS) {
        // eslint-disable-next-line max-len, prettier/prettier
        const { tripDuration, tripDistance, timeZone, date, gpsReliability, ...rest } =
          filters.by as ITripsFilters;
        setBody(prev => ({
          ...rest,
          ...prev,
          gpsReliability: gpsReliability || '',
          date: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            startDate: date?.from ? dayjs(date.from).format('YYYY-MM-DDTHH:mm:ss') : date?.from,
            endDate: date?.to ? dayjs(date.to).format('YYYY-MM-DDTHH:mm:ss') : date?.to
          },
          tripDistance:
            tripDistance?.from?.toString() && tripDistance?.to
              ? {
                  from: tripDistance?.from,
                  to: tripDistance?.to
                  // from: isMetric ? tripDistance?.from :
                  // formatDistance(tripDistance?.from, 'miles'),
                  // to: isMetric ? tripDistance?.to : formatDistance(tripDistance?.to, 'miles')
                }
              : null,
          tripDuration:
            (tripDuration?.from || tripDuration?.from === 0) &&
            (tripDuration?.to || tripDuration?.to === 0)
              ? {
                  from: tripDuration.from * 60,
                  to: tripDuration.to * 60
                }
              : null,
          isMetric
        }));
      }
      const commonBody = {
        customerId: customer.id,
        pageIndex: (pagination?.currentPage || 1) - 1,
        pageSize: pagination?.showBy || 10,
        searchKey: search?.by ? search.by : crossFilterSource ? params.filter : '',
        sort: {
          fieldName:
            sort?.by === 'tollogic™ Tolls' || sort?.by === 'GPS Calculated Tolls'
              ? 'estimated toll'
              : sort?.by || '',
          order: _.upperCase(sort?.order || '')
        }
      };

      let specificQuickFilter;
      if (table === ETableName.FLEET) {
        specificQuickFilter = { ...quickFilters.fleet };
        specificQuickFilter.missingItems = (specificQuickFilter.missingItems as string[]).map(
          (label: string) => {
            const type = fleetHelperTypes.find(item => item.label === label);
            return type?.value ?? 0;
          }
        );
      } else if (table === ETableName.TRIPS) {
        const modifiedTripQuickFilters = { ...quickFilters.trips };
        if (
          modifiedTripQuickFilters.tollAmount &&
          modifiedTripQuickFilters.tollAmount.from === 0 &&
          modifiedTripQuickFilters.tollAmount.to === 0
        ) {
          delete modifiedTripQuickFilters.tollAmount;
        }
        specificQuickFilter = modifiedTripQuickFilters;
      }

      const bodyWithSpecificQuickFilter = {
        ...commonBody,
        ...(specificQuickFilter ? { quickFilter: specificQuickFilter } : {})
      };

      setBody(prev => ({
        ...prev,
        ...bodyWithSpecificQuickFilter
      }));

      if (crossFilterSource) {
        if (crossFilterSource === ETableName.TRIPS) {
          setBody(prev => ({
            ...prev,
            searchableField: 'NAME',
            crossFilterSource: true
          }));
        } else if (crossFilterSource === ETableName.FLEET) {
          setBody(prev => ({
            ...prev,
            searchableField: 'VEHICLE NAME',
            crossFilterSource: true
          }));
        }
      } else {
        delete body.crossFilterSource;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters?.by,
    isMetric,
    table,
    crossFilterSource,
    quickFilters,
    customer.id,
    pagination?.currentPage,
    pagination?.showBy,
    params.filter,
    params.destination,
    params.document,
    search?.by,
    sort?.by,
    sort?.order,
    search?.field,
    isGeotab
  ]);
  return body;
};
