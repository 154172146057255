/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint @typescript-eslint/restrict-template-expressions: "off" */
// eslint-disable-next-line import/no-extraneous-dependencies
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { ICalculatedToll } from '@api/types';
import api from '@api/services/common';
import { IBilledToll } from '@api/types/trip-response';
import Table from './Table';

const TripTransaction = ({ tripId }: { tripId: string }) => {
  const [calculatedData, setCalculatedData] = useState<ICalculatedToll[]>([]);
  const [billData, setBillData] = useState<IBilledToll[]>([]);
  const [calculatedLoading, setCalculatedLoading] = useState(true);
  const [billLoading, setBillLoading] = useState(true);

  useEffect(() => {
    setBillLoading(true);
    setCalculatedLoading(true);

    let calcData: ICalculatedToll[] = [];
    let billData: IBilledToll[] = [];

    // Function to handle the combined data processing
    const processAndUpdateData = () => {
      if (calcData.length > 0 && billData.length > 0) {
        const unmatchedTolls: ICalculatedToll[] = calcData.filter(
          item => item?.missedTollUpdatedAt !== null
        );

        // Map unmatchedTolls to IBilledToll
        const convertedTolls: IBilledToll[] = unmatchedTolls.map(toll => ({
          state: toll.state || '', // Default to empty string if state is undefined
          agency_name: toll.agencyName || '', // Default to empty string if agencyName is undefined
          src_exit_plaza: toll.plaza || '', // Default to empty string if plaza is undefined
          amount: toll.calculatedAmount.toString(), // Convert calculatedAmount to string
          id: toll.id,
          missedTollUpdatedAt: toll.missedTollUpdatedAt,
          src_exit_date_time: toll?.exitTime || '',
          timezone: toll?.endTimeZone
          //  moment
          //   .utc(toll?.exitTime, 'MM-DD-YYYY HH:mm:ss')
          //   .toISOString()
        }));

        // Merge convertedTolls into billData without duplicates (based on id)
        const unmatchedTollIds = new Set(convertedTolls.map(toll => toll.id));
        const uniqueBillData = billData.filter(bill => !unmatchedTollIds.has(bill?.id));

        const updatedBillData = [...uniqueBillData, ...convertedTolls];

        // Sort by date in descending order using moment
        const sortedData = updatedBillData.sort((a, b) => {
          const dateA = moment(a.src_exit_date_time, 'MM-DD-YYYY HH:mm:ss').toDate().getTime();
          const dateB = moment(b.src_exit_date_time, 'MM-DD-YYYY HH:mm:ss').toDate().getTime();
          return dateB - dateA; // Descending order (recent first)
        });

        setBillData(sortedData);
      }
    };

    api
      .JavaGet(`/calc-tolls/v1?trip-id=${tripId}`)
      .then((res: ICalculatedToll[]) => {
        calcData = res;
        setCalculatedData(res);
        processAndUpdateData();
        setCalculatedLoading(false);
      })
      .catch(() => {
        setCalculatedLoading(false);
      });
    api
      .get(`/trips/billed-tolls?trip_id=${tripId}`)
      .then((res: { data: IBilledToll[]; isSuccess: boolean }) => {
        billData = res.data;
        setBillData(res.data);
        processAndUpdateData();
        setBillLoading(false);
      })
      .catch(() => {
        setBillLoading(false);
      });
  }, [tripId]);

  return (
    <>
      <Table title="tollogic&#8482; Tolls" isLoading={calculatedLoading} data={calculatedData} />
      <Table title="Invoiced Tolls" isLoading={billLoading} data={billData} />
    </>
  );
};

export default TripTransaction;
